import { useMemo, useRef, useCallback, useEffect } from 'react'
import { useFt } from 'hooks'
import { constants } from 'helpers'
import { debounce } from 'throttle-debounce'

import { useAddressDetails, useAddressSuggestions } from 'modules/user/shipping'

import messages from '../messages'


type FillInAddressParams = {
  form: UserModule.ShippingForm
  autocomplete: Omit<UserModule.ShippingFormFields, 'firstName' | 'lastName' | 'country' | 'phone'>
  blockedRegions: Record<string, string[]>
}

const fillInAddress = ({ form, autocomplete, blockedRegions }: FillInAddressParams) => {
  const fieldsData = {
    ...form.getValues(),
    region: '',
    city: '',
    street1: '',
    postalCode: '',
  }

  Object.keys(autocomplete).forEach((fieldName) => {
    if (fieldName in fieldsData && autocomplete[fieldName]) {
      fieldsData[fieldName] = autocomplete[fieldName]
    }
  })

  const statesForFilter = blockedRegions[fieldsData.country] || []
  const hasBlockedState = statesForFilter.includes(fieldsData.region)

  if (!hasBlockedState) {
    form.setValues(fieldsData)

    // After blur field.debounceValidate() to be called, in that case if user enter "Test" to street field
    // and select a google suggestion the field's state becomes invalid (validation not passed) but the selected
    // suggestion value "Test Drive" is correct, so to fix this need to call .validate() to update Field state
    // update: same for all other fields
    void form.validate()

    if (form.fields.street2.node) {
      form.fields.street2.node.focus()
    }
  }
  else {
    const regions = statesForFilter.join(', ')
    const message = { ...messages.stateError, values: { regions } }

    form.fields.street1.setError(message)
  }
}

const useAutocomplete = ({ form, country }: { form: UserModule.ShippingForm, country: string }) => {
  const { street1: street } = form.fields

  const isAddQuebecToCanadaEnabled = useFt(constants.features.addQuebecToCanada)

  const detailedStreet = useRef(null)

  const { autocompleteDetails, getAutocompleteDetails } = useAddressDetails()
  const { addressSuggestions, getAddressSuggestions, isFetching } = useAddressSuggestions()

  const isSuggestionsActive = street.state.value !== detailedStreet.current

  const blockedRegions = useMemo(() => ({
    CA: isAddQuebecToCanadaEnabled ? [] : [ 'QC' ],
    US: [ 'AA', 'AE', 'AP' ],
    GB: [],
  }), [ isAddQuebecToCanadaEnabled ])

  // Fill form with autocomplete data when user selects a suggestion
  useEffect(() => {
    if (autocompleteDetails) {
      detailedStreet.current = autocompleteDetails.street1

      fillInAddress({ form, autocomplete: autocompleteDetails, blockedRegions })
    }
  }, [ form, autocompleteDetails, blockedRegions ])

  const selectSuggestion = useCallback( (suggestion) => {
    // if user selects nothing on tab
    if (!suggestion) {
      return
    }

    // Returned type ADDRESS means that final address found, and we can get details of it by separate gql
    if (suggestion.type === 'ADDRESS') {
      return getAutocompleteDetails({
        variables: {
          input: {
            placeId: suggestion.placeId,
            apiVersion: 'V2',
          },
        },
      })
    }

    // Another returned type means that not a final address found,and we have to do
    // one more suggestion request with containerId if it is returned from previous call
    return getAddressSuggestions({
      variables: {
        input: {
          country,
          query: street.state.value,
          containerId: suggestion.placeId,
          apiVersion: 'V2',
        },
      },
    })
  }, [ country, street, getAutocompleteDetails, getAddressSuggestions ])

  const handleStreetChange = useMemo(() => debounce( (query) => {
    if (detailedStreet.current === query || !query) return

    return getAddressSuggestions({
      variables: {
        input: {
          query,
          country,
          apiVersion: 'V2',
        },
      },
    })
  }, 300), [ country, getAddressSuggestions ])

  return { addressSuggestions, selectSuggestion, isSuggestionsActive, handleStreetChange, isSuggestionsFetching: isFetching }
}

export default useAutocomplete
