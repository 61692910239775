import React, { useEffect, useMemo } from 'react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'
import { array } from 'helpers/primitives'

import type { InputProps } from 'components/inputs'
import { Input } from 'components/inputs'
import { Text } from 'components/dataDisplay'

import getPasswordStrength, { type PasswordStrength } from './util/getPasswordStrength'

import s from './PasswordInput.module.css'
import messages from './messages'


export type { PasswordStrength } from './util/getPasswordStrength'

export type PasswordInputProps = Omit<InputProps, 'type'> & {
  strengthField: Field<PasswordStrength>
}

const PasswordInput: React.FunctionComponent<PasswordInputProps> = (props) => {
  const { field, strengthField, ...rest } = props
  const { value, isChanged } = useFieldState(field)

  const bars = useMemo(() => array.range(1, 3), [])

  const { strength, text } = useMemo(() => {
    const strength = getPasswordStrength(value as string)
    const text = { ...messages.text, values: { strength } }

    return {
      strength,
      text,
    }
  }, [ value ])

  useEffect(() => {
    strengthField.set(strength)
  }, [ strengthField, strength ])

  return (
    <>
      <Input
        {...rest}
        field={field}
        type="password"
        aria-describedby="passwordStrength"
      />
      {
        isChanged && Boolean(value) && (
          <div className={s[strength?.toLowerCase()]}>
            <div className="mt-8 flex">
              {
                bars.map((count, index) => {
                  const className = cx(s.bar, 'flex-1', {
                    'ml-4': index > 0,
                  })

                  return (
                    <div key={count} className={className} />
                  )
                })
              }
            </div>
            <Text
              id="passwordStrength"
              className="mt-4"
              message={text}
              style="t3"
              data-testid="passwordStrength"
            />
          </div>
        )
      }
    </>
  )
}


export default PasswordInput
