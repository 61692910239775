import type { PasswordStrength } from 'typings/graphql'


export type { PasswordStrength } from 'typings/graphql'


const deleteRepetition = (count: number, str: string): string => {
  let result = ''
  let repeated = false

  if (typeof str !== 'string') {
    console.error('str prop in deleteRepetition() is not a string!')
    return str
  }

  for (let i = 0; i < str.length; i++) {
    let j = 0
    repeated = true

    for (j; j < count && (j + i + count) < str.length; j++) {
      repeated = repeated && (str.charAt(j + i) === str.charAt(j + i + count))
    }

    if (j < count) {
      repeated = false
    }

    if (repeated) {
      i += count - 1
    }
    else {
      result += str.charAt(i)
    }
  }

  return result
}

// min score value is -40, max is 30
const calculateLengthStrength = (password: string): number => {
  let score = 0

  // get real length (delete repetition)
  const { length } = deleteRepetition(2, password)

  if (length < 5) {
    score -= 40
  }

  if (length > 4 && length < 7) {
    score -= 20
  }

  if (length > 6) {
    score += 10
  }

  if (length > 9) {
    score += 10
  }

  if (length > 14) {
    score += 10
  }

  return score
}

const calculateStrengthScore = (password: string): number | null => {
  if (typeof password !== 'string' || password === '') {
    return null
  }

  let score = 0

  score += calculateLengthStrength(password)

  // has just numbers
  if (/^\d+$/.test(password)) {
    score -= 20
  }

  // has Upper and Lower chars
  if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
    score += 20
  }

  // has chars and one number
  if (/([a-zA-Z])/.test(password) && /([0-9])/.test(password)) {
    score += 20
  }

  // has chars and two numbers
  if (/([a-zA-Z])/.test(password) && /([0-9]\D*[0-9])/.test(password)) {
    score += 20
  }

  // has a symbol
  if (/([!@#$%^&*?_~])/.test(password)) {
    score += 20
  }

  if (score > 100) {
    score = 100
  }

  if (score < 0) {
    score = 0
  }

  return score
}

const getStrengthType = (score: number): PasswordStrength => {
  if (typeof score !== 'number') {
    return null
  }

  if (score <= 30) {
    return 'WEAK'
  }

  if (score > 30 && score < 70) {
    return 'MEDIUM'
  }

  if (score >= 70) {
    return 'STRONG'
  }
}

const getPasswordStrength = (password: string): PasswordStrength => {
  const score = calculateStrengthScore(password)

  return getStrengthType(score)
}


export default getPasswordStrength
