const validDomains = [
  'me.com',
  'aol.com',
  'att.net',
  'gmx.com',
  'msn.com',
  'mac.com',
  'mail.com',
  'live.com',
  'ymail.com',
  'gmail.com',
  'yahoo.com',
  'google.com',
  'icloud.com',
  'comcast.net',
  'hotmail.com',
  'verizon.net',
  'facebook.com',
  'sbcglobal.net',
  'googlemil.com',
]

// ATTN this is a copy-paste from of the old implementation, if we need to change the logic, just do it :)
const getSuggestion = (email: string): string => {
  const name = email.replace(/@.*/, '')
  const domain = email.toLowerCase().replace(/.*@/, '')
  const clearDomain = (
    domain
      .replace(/[^a-z.]/g, '') // remove everything except dot and letters
      .replace(/\.{2,}/g, '.') // replace multiple dots with single dot
      .replace(/^[^a-z]+/, '') // replace everything except letters at the beginning of string
      .replace(/[^a-z]+$/, '') // replace everything except letters at the end of string
      .replace(/(\.[a-z]+).*$/, '$1') // replace smth weird at the end (example: gmail.com.com -> gmail.com)
  )
  const isDomainCorrect = /.+\..+/.test(clearDomain)
  const domainsList = clearDomain.split('.')
  const isInList = validDomains.includes(clearDomain)

  if (!name || !clearDomain || !isDomainCorrect) {
    return ''
  }

  // if modified domain is in list but initial email state is not same
  // example: foo@.gmail.com
  if (isInList && domain !== clearDomain) {
    return clearDomain
  }

  if (!isInList) {
    const userDomain = domainsList[domainsList.length - 2]

    const suggestedDomain = validDomains
      .reduce((a, b) => {
        const maxLength = userDomain.length
        const newLength = b.replace(/\..*/, '').length
        const oldLength = a.replace(/\..*/, '').length
        const isAllowedLength = newLength === maxLength || oldLength !== maxLength && (newLength === maxLength + 1)

        const oldSameIndexCount = a.split('').reduce((a, b, index) => b === userDomain[index] ? 1 + a : a, 0)
        const newSameIndexCount = b.split('').reduce((a, b, index) => b === userDomain[index] ? 1 + a : a, 0)
        const isAllowedIndexCount = newSameIndexCount >= (maxLength > 3 ? 2 : 1)
        const isIndexCountHigher = newSameIndexCount > oldSameIndexCount

        const isSuggestedB = isAllowedIndexCount && isIndexCountHigher && isAllowedLength

        return isSuggestedB ? b : a
      }, '')

    if (suggestedDomain) {
      return `${name}@${suggestedDomain}`
    }
  }

  return ''
}


export default getSuggestion
