import React from 'react'
import { Message } from 'intl'
import { type Field } from 'formular'

import { Text } from 'components/dataDisplay'
import { Input, type InputProps } from 'components/inputs'

import { useEmailSuggestion } from './util'

import messages from './messages'


type EmailInputProps = InputProps & {
  field: Field<string>
}

const EmailInput: React.FunctionComponent<EmailInputProps> = (props) => {
  const { field, ...rest } = props
  const { suggestion, domain, confirm } = useEmailSuggestion(field)

  return (
    <>
      <Input
        type="email"
        field={field}
        {...rest}
      />
      {
        Boolean(suggestion) && (
          <div className="bg-light-beige mt-8 flex items-center justify-between py-8 pl-16 pr-8">
            <Text
              style="p4"
              message={{ ...messages.text, values: { domain } }}
              html
            />
            <button
              className="ml-8 bg-white px-16 py-4 text-[14rem] font-medium uppercase"
              type="button"
              onClick={confirm}
            >
              <Message value={messages.button} />
            </button>
          </div>
        )
      }
    </>
  )
}


export default EmailInput
