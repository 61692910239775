import React from 'react'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'


type SuggestionProps = {
  suggestion: UserModule.AddressSuggestion
  onSelectContainer: (suggestion: UserModule.AddressSuggestion) => void
}

const Suggestion: React.FunctionComponent<SuggestionProps> = (props) => {
  const { suggestion, onSelectContainer } = props
  const { mainText, secondaryText } = suggestion

  const { isMobile } = useDevice()

  const onSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    if (suggestion.type === 'CONTAINER') {
      event.stopPropagation()

      onSelectContainer(suggestion)
    }
  }

  return (
    <div
      onClick={onSelect}
      data-testid="suggestion"
    >
      <Text
        className={!isMobile && 'mr-8 inline-block'}
        message={mainText}
        style="p2"
        data-testid="suggestion-main-text"
      />
      <Text
        className={!isMobile && 'inline-block'}
        message={secondaryText}
        style="p2"
        color="gray-50"
        data-testid="suggestion-secondary-text"
      />
    </div>
  )
}

export default Suggestion
