import { useCallback, useMemo, useRef } from 'react'
import { type Field, useFieldState } from 'formular'
import { track } from 'analytics'
import getSuggestion from './getSuggestion'


export const useEmailSuggestion = (field: Field<string>) => {
  const { value } = useFieldState(field)
  const suggestionRef = useRef<string>(null)

  suggestionRef.current = useMemo(() => getSuggestion(value), [ value ])

  const domain = suggestionRef.current.replace(/.*@/, '')

  const confirm = useCallback(() => {
    track('Email fix')
    field.set(suggestionRef.current)
  }, [ field ])

  return {
    suggestion: suggestionRef.current,
    domain,
    confirm,
  }
}

