import React from 'react'
import cx from 'classnames'
import type { Field } from 'formular'

import { InputError, Select } from 'components/inputs'

import useDatePartsInput from './util/useDatePartsInput'

import messages from './messages'


type DatePartsInputProps = {
  className?: string
  field: Field<string>
  'data-testid'?: string
}

const DatePartsInput: React.FunctionComponent<DatePartsInputProps> = (props) => {
  const { className, field, 'data-testid': dataTestId = 'date' } = props

  const {
    form,
    error,
    dayOptions,
    monthOptions,
    yearOptions,
  } = useDatePartsInput(field)

  return (
    <div className={cx(className, 'grid grid-cols-3 gap-16')} data-testid={dataTestId}>
      <Select
        field={form.fields.month}
        size={48}
        label={messages.month}
        options={monthOptions}
        data-testid={`${dataTestId}Month`}
      />
      <Select
        field={form.fields.day}
        size={48}
        label={messages.day}
        options={dayOptions}
        data-testid={`${dataTestId}Day`}
      />
      <Select
        field={form.fields.year}
        size={48}
        label={messages.year}
        options={yearOptions}
        data-testid={`${dataTestId}Year`}
      />
      {
        Boolean(error) && (
          <InputError
            className="mt-8"
            message={error}
            data-testid={`${dataTestId}Error`}
          />
        )
      }
    </div>
  )
}


export default DatePartsInput
