export default {
  month: {
    en: 'Month',
  },
  day: {
    en: 'Day',
  },
  year: {
    en: 'Year',
  },
}
