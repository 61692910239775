import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { useFieldState } from 'formular'

import { InputWithAutocomplete } from 'components/inputs'

import useAutocomplete from './util/useAutocomplete'

import Suggestion from './components/Suggestion/Suggestion'


const LoqateStreetAddressInput: React.FunctionComponent<UserModule.AddressInputProps> = (props) => {
  const { form, field, label, 'data-testid': dataTestId, inputClassName = '' } = props
  const { isMobile } = useDevice()

  const { value: country } = useFieldState(form.fields.country)

  const { addressSuggestions, selectSuggestion, isSuggestionsFetching, handleStreetChange, isSuggestionsActive } = useAutocomplete({ form, country })

  return (
    <div className="relative">
      <InputWithAutocomplete
        inputClassName={cx(inputClassName, isMobile && 'text-[16px]')}
        field={field}
        label={label}
        isFetching={isSuggestionsFetching}
        onChange={handleStreetChange}
        onSelect={selectSuggestion}
        inputTestId={dataTestId}
        containerTestId="suggestions"
        isSuggestionsActive={isSuggestionsActive}
      >
        {
          addressSuggestions?.map((suggestion) => (
            <InputWithAutocomplete.Option
              key={suggestion.placeId}
              value={suggestion}
            >
              <Suggestion suggestion={suggestion} onSelectContainer={selectSuggestion} />
            </InputWithAutocomplete.Option>
          ))
        }
      </InputWithAutocomplete>
    </div>
  )
}


export default LoqateStreetAddressInput
