import { useEffect, useMemo, useRef } from 'react'
import { useFieldState, useForm } from 'formular'
import type { Field } from 'formular'
import dayjs, { type Dayjs } from 'date'
import { array } from 'helpers'


const currentYear = new Date().getFullYear()

const createOptions = (items: number[]) => items.map((item) => ({
  title: item.toString(),
  value: item.toString(),
}))

const parseDate = (value?: string) => {
  if (value) {
    const date = dayjs(value)

    if (date.isValid()) {
      return date
    }
  }

  // at least tomorrow
  return dayjs().add(1, 'day')
}

const getInitialValues = (date: Dayjs) => {
  return {
    year: date.year().toString(),
    month: (date.month() + 1).toString(),
    day: date.date().toString(),
  }
}

const useDatePartsInput = (field: Field<string>) => {
  const { value, error } = useFieldState(field)
  const lastValueRef = useRef(value)

  const dateRef = useRef(null)

  if (!dateRef.current) {
    dateRef.current = parseDate(value)
  }

  const form = useForm<{ year: string, month: string, day: string }>({
    fields: {
      year: [],
      month: [],
      day: [],
    },
    initialValues: getInitialValues(dateRef.current),
  })

  useEffect(() => {
    if (lastValueRef.current !== value) {
      lastValueRef.current = value
      dateRef.current = parseDate(value)

      form.setValues(getInitialValues(dateRef.current))
    }
  }, [ form, value ])

  useEffect(() => {
    const sync = () => {
      const values = form.getValues()

      const year = Number.parseInt(values.year)
      const month = Number.parseInt(values.month)
      const day = Number.parseInt(values.day)

      let date = dayjs().year(year).month(month - 1)

      if (day > date.daysInMonth()) {
        date = date.date(date.daysInMonth())
        form.fields.day.set(date.daysInMonth())
      }
      else {
        date = date.date(day)
      }

      dateRef.current = date

      // update the real field
      lastValueRef.current = date.format('YYYY-MM-DD')
      field.set(lastValueRef.current)
    }

    sync()

    form.on('change', sync)

    return () => {
      form.off('change', sync)
    }
  }, [ field, form ])

  const [
    monthOptions,
    dayOptions,
    yearOptions,
  ] = useMemo(() => {
    const date = dateRef.current || parseDate(value)

    return [
      createOptions(array.range(1, 12)),
      createOptions(array.range(1, date.daysInMonth())),
      createOptions(array.range(currentYear, currentYear + 2)),
    ]
  }, [ value ])

  return {
    form,
    error,
    monthOptions,
    dayOptions,
    yearOptions,
  }
}


export default useDatePartsInput
